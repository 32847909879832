import React, { useState, useEffect, useContext } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AssessmentProgressContext from '../Store/AssessmentProgressContext';
import PreAdditionalQuestionContext from '../Store/PreAdditionalQuestionContext';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

const PreAdditionalQuestions = (props) => {

  const normalColor = "#343333";
  const activeColor = "#F1D665";

  let navigate = useNavigate();
  const { id } = useParams();
  const assessProgressCtx = useContext(AssessmentProgressContext);
  const preAddCtx = useContext(PreAdditionalQuestionContext);
  console.log("preAddCtx", preAddCtx);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => {
      if(sec.title === "Response") return { ...sec, status: "finished" };
      if(sec.title === "Relevance") return { ...sec, status: "active" };
      else return { ...sec, status: "unfinished" };
    }));
  }, [])

  useEffect(() => {
    // for (const [key, field] of Object.entries(formFields)) {
    //   console.log("key, field", key, field);
    //   if(formFields[key] !== preAddCtx?.answers[key]) {
    //     setFormFields((prev) => ({ ...prev, key: formFields[key] }))
    //   }
    // }
    if(preAddCtx.answers[0]) setSelectedValue({ value: preAddCtx.answers[0], label: preAddCtx.answers[0] });
    else setSelectedValue(undefined);
  }, [preAddCtx.answers])


  const [formFields, setFormFields] = useState({
    field1: preAddCtx?.answers[0], field2: preAddCtx?.answers[1], field3: preAddCtx?.answers[2], 
    field4: preAddCtx?.answers[3], field5: preAddCtx?.answers[4], field6: preAddCtx?.answers[5]
  })

  const [formFieldsErr, setFormFieldsErr] = useState({
    field1Err: false, field2Err: false, field3Err: false, 
    field4Err: false, field5Err: false, field6Err: false
  })
  
  // useEffect(() => {
  //   const localStoredPreAddQuestions = JSON.parse(localStorage.getItem("preAdditionalAnswers"));
  //   console.log("localStoredPreAddQuestions", localStoredPreAddQuestions);
  //   if(localStoredPreAddQuestions?.length && localStoredPreAddQuestions.some(elm => elm != "")) {
  //     preAddCtx.setAns(localStoredPreAddQuestions);
  //   }
  // }, []);

  useEffect(() => {
    const localStoredPreAddQuestions = JSON.parse(localStorage.getItem("preAdditionalAnswers"));
    // console.log("localStoredPreAddQuestions", localStoredPreAddQuestions);
    if(preAddCtx.answers?.length && preAddCtx.answers.some(elm => elm != "")) {
      let arr = Object.keys(formFields);
      for (const [key, field] of Object.entries(formFields)) {
        console.log("key, field", key, field);
        let currIdx = arr.indexOf(key);
        console.log(key, localStoredPreAddQuestions[currIdx]);
        setFormFields((prev) => ({ ...prev, key: preAddCtx.answers[currIdx] }));
        // if(formFields[key] !== preAddCtx?.answers[key]) {
        //   setFormFields((prev) => ({ ...prev, key: formFields[key] }))
        // }
      }
    }
  }, [preAddCtx, preAddCtx?.answers])

  console.log("formFields", formFields);
  
  const updateFormFields = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    // setFormFields((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    if (e.target.name == "field2") preAddCtx.updateAnswer(2, e.target.value);
    if (e.target.name == "field3") preAddCtx.updateAnswer(3, e.target.value);
    if (e.target.name == "field4") preAddCtx.updateAnswer(4, e.target.value);
    if (e.target.name == "field5") preAddCtx.updateAnswer(5, e.target.value);
    if (e.target.name == "field6") preAddCtx.updateAnswer(6, e.target.value);
    setFormFields((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    checkFormValid();
  }

  const updateFormFieldsErrors = (field, bool) => {
    setFormFieldsErr((prevState) => ({ ...prevState, [field]: bool }))
  }

  console.log("formFields", formFields);
  console.log("selectedValue", selectedValue);
  console.log("formFieldsErr", formFieldsErr);

  const updateSelectField = (selectedOption) => {
    // console.log(event.target.value);
    console.log(selectedOption.value);
    setSelectedValue(selectedOption);
    preAddCtx.updateAnswer(1, selectedOption.value);
    setFormFields((prev) => ({ ...prev, field1: selectedOption.value }))
    checkFormValid();
  }


  const checkFormValid = () => {
    if (formFields?.field1 && formFields?.field2 && formFields?.field3 && formFields?.field4 && formFields?.field5 && formFields?.field6) {
      setIsFormValid(true);
    } else setIsFormValid(false);
  }

  const showErrors = () => {
    for (const [key, field] of Object.entries(formFields)) {
      if(!field) updateFormFieldsErrors(`${key}Err`, true);
    }
  }

  const submitToApi = () => {

    let apiCall = '/v1/assessment/' + id + '/additionalForm';

    let jsonBody = JSON.stringify({
      details: {
        "field1": formFields?.field1,
        "field2": formFields?.field2,
        "field3": formFields?.field3,
        "field4": formFields?.field4,
        "field5": formFields?.field5,
        "field6": formFields?.field6,
      },
      assessmentId: process.env.REACT_APP_CLIENT_ID,
      formVersion: 2
    });

    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        setIsLoading(false);
        navigate("/pre-additional-info2/" + id + "?" + searchParams, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      console.error("Error submitting church context form data...", err);
      setIsLoading(false);
    });
    
    // remove this after updating API endpoint 
    // navigate("/pre-additional-info2/" + id + "?" + searchParams, { replace: true });

  }

  const handleSubmit = () => {
    setIsSaving(true);
    
    if (formFields?.field1 && formFields?.field2 && formFields?.field3 && formFields?.field4 && formFields?.field5 && formFields?.field6) {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }
  }

  const showButton = () => {
    if (isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#F1D665' }} size={23} /></div>
    }
    return "SHOW MY RESULTS";
  }

  const showHintText = (field) => {
    if (field == "field1") {
      if (formFieldsErr?.field1Err) {
        return <div className="error-text">Please select your current ChMS.</div>
      }
    }
    if (field == "field2") {
      if (formFieldsErr?.field2Err) {
        return <div className="error-text">Please confirm your in-person weekend attendance.</div>
      }
    }
    if (field == "field3") {
      if (formFieldsErr?.field3Err) {
        return <div className="error-text">Please confirm the total staff members using your ChMS.</div>
      }
    }
    if (field == "field4") {
      if (formFieldsErr?.field4Err) {
        return <div className="error-text">Please confirm your online weekend attendance.</div>
      }
    }
    if (field == "field5") {
      if (formFieldsErr?.field5Err) {
        return <div className="error-text">Please confirm the % of annual budget spent on your ChMS.</div>
      }
    }
    if (field == "field6") {
      if (formFieldsErr?.field6Err) {
        return <div className="error-text">Please confirm your 12-month attendance goal.</div>
      }
    }
  }

  const removeHintText = (field, e) => {

    // change active input field title color 
    if (e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => {
        elm.style.color = normalColor;
        elm.style.fontWeight = 400;
      })
      let inputField = e.target.closest(".form-detail").querySelector(".input-title");
      inputField.style.color = activeColor;
      inputField.style.fontWeight = 700;
    }

    for (const [key, formField] of Object.entries(formFields)) {
      if(field == key.toString()) updateFormFieldsErrors(`${key}Err`, false);
    }
  }

  const prevQuestion = () => {
    // navigate("/assessment-additional/" + id, { replace: true });
    if (!searchParams.get("leader") && [...searchParams]?.length) {
      navigate('/assessment/' + id + '/review?' + searchParams, { replace: true });
    } else {
      navigate('/assessment/' + id + '/review', { replace: true });
    }
  };

  const nextPage = () => {
    handleSubmit();
    // if(!searchParams.get("leader")) {
    //   navigate("/pre-additional-questions/" + id, { replace: true });
    // } else navigate("/assessment-additional/" + id + "?" + searchParams, { replace: true });
  }
  
  const prevPage = () => {
    if(!searchParams.get("leader")) {
      navigate("/pre-additional-info/" + id, { replace: true });
    } else navigate("/assessment-additional/" + id + "?" + searchParams, { replace: true });
  }

  const reactSelectColourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected, ...rest }) => {
      // console.log({ data, isDisabled, isFocused, isSelected, rest });
      return {
        ...styles,
        backgroundColor: isSelected ? "#F4D62B" : isFocused ? "#F4D62B77" : null,
        color: "#333333"
      };
    }
  };

  if (isLoading) {
    return <div className="container details loading"><CircularProgress style={{ color: '#F7D54D' }} size={60} /></div>
  }

  return (
    <div className="container pre-additional-questions-details">

      <div className="details-container">

        <h1>ChMS/Church Context</h1>
        <div style={{ height: '10px' }}></div>
        {/* <p>Before we get to your report, please confirm<br />your details so that we can field4 you a copy.</p> */}

        <div className="form">

          <div className="form-detail">
            <p className="input-title">Current ChMS:<span className="required-field">*</span></p>

            {/* Added react-select instead of MUI TextField */}
            {/* <Select classNamePrefix="react-select" className="form-details-text react-select" */}
            <CreatableSelect classNamePrefix="react-select" className="form-details-text react-select"
              onCreateOption={(e) => {
                preAddCtx.setField1Values((prev) => ([ ...prev, { value: e.toString().trim(), label: e.toString().trim() }]));
                updateSelectField({ value: e.toString().trim(), label: e.toString().trim() });
              }}
              options={preAddCtx.field1Values}
              onFocus={(e) => removeHintText("field1", e)}
              onChange={updateSelectField}
              value={selectedValue}
              theme={(theme) => {
                return {
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: activeColor, // selected option color 
                    primary25: '#FFF4C6', // cursor movement highlight 
                    neutral0: '#F4F4F4', // options list background 
                    neutral50: '#A5A5A5', // placeholder text color 
                  },
                }
              }}
              styles={reactSelectColourStyles}
              placeholder={" - Select your ChMS - "} />
            {showHintText("field1")}
          </div>

          <div className="form-detail">
            <p className="input-title">In-person weekend attendance:<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="e.g. 1,000" name="field2" value={formFields?.field2} onFocus={(e) => removeHintText("field2", e)} onChange={(e) => { updateFormFields(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("field2")}
          </div>

          <div className="form-detail">
            <p className="input-title">Total staff members using ChMS:<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="e.g. 10" name="field3" value={formFields?.field3} onFocus={(e) => removeHintText("field3", e)} onChange={(e) => { updateFormFields(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("field3")}
          </div>

          <div className="form-detail">
            <p className="input-title">Online weekend attendance:<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="e.g. 1,000-2,000" name="field4" value={formFields?.field4} onFocus={(e) => removeHintText("field4", e)} onChange={(e) => { updateFormFields(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("field4")}
          </div>

          <div className="form-detail school-name">
            <p className="input-title">% of annual budget spent on ChMS:<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="e.g. 12%" name="field5" value={formFields?.field5} onFocus={(e) => removeHintText("field5", e)} onChange={(e) => { updateFormFields(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("field5")}
          </div>

      
          <div className="form-detail">
            <p className="input-title">Total attendance goal in 12 months:<span className="required-field">*</span></p>
            <TextField hiddenLabel
              type="text"
              placeholder="e.g. 5,000"
              className="form-details-text"
              name="field6" value={formFields?.field6}
              onFocus={(e) => removeHintText("field6", e)}
              onChange={(e) => { updateFormFields(e) }}
              fullWidth variant="standard" size="normal"
              InputProps={{ disableUnderline: true }}
              onInput={(e) => {
                return e.target.value = e.target.value?.toString().slice(0, 12)
              }}
            />
            {showHintText("field6")}
          </div>


        </div>
        {/* <div className="details-buttons">
          <div className="back-btn">
            <Button onClick={prevQuestion}>
              <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;
            </Button>
          </div>

          <div className="cta-btn">
            <Button onClick={handleSubmit} disabled={isSaving}>
              {showButton()}
            </Button>
          </div>
        </div> */}

        <div className="step-buttons">
        <Button className="cta-prev" onClick={() => prevPage()}>
          <img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;PREV
        </Button>
        <Button className="cta" onClick={() => nextPage()}>NEXT&nbsp;&nbsp;<img src={nextArrow} alt="Previous Page Arrow" /></Button>
      </div>

      </div>

    </div>
  );

}
export default PreAdditionalQuestions;
