import { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import prevArrow from "../Assets/Images/prev-arrow.svg";
import nextArrow from "../Assets/Images/next-arrow.svg";
import AssessmentProgressContext from "../Store/AssessmentProgressContext";

const PreAdditionalInfo2 = () => {

  const { id }                          = useParams();
  const assessProgressCtx               = useContext(AssessmentProgressContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate                        = useNavigate();

  useEffect(() => {
    localStorage.setItem("backFromDetailsPage", "false");
    assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => {
      if (sec.title === "Rating") return { ...sec, status: "active" };
      else return { ...sec, status: "finished" };
    }));
  }, []);

  const nextPage = () => {
    // if(searchParams.get("leader") || searchParams.get("member")) {
    //   navigate("/assessment-additional/" + id + "?" + searchParams, { replace: true });
    // } else navigate("/assessment-additional/" + id + '?' + searchParams, { replace: true });
    navigate("/assessment-additional/" + id + '?' + searchParams, { replace: true });
  }

  
  const prevPage = () => {
    if(!searchParams.get("leader") || !searchParams.get("member")) {
      navigate("/pre-additional-questions/" + id, { replace: true });
    } else navigate("/assessment/" + id + "/review?" + searchParams, { replace: true });
  }

  return (
    <div className="container pre-additional-info">
      <h1>Thanks, you're almost there—{searchParams.get("leader") ? "75%" : "82%"} to be precise!</h1>
      <div className="text-container">
        <p>This final rating section {searchParams.get("leader") ? "" : "(which is for you and your team)"} contains 4 questions that will help detail and summarize your feedback about your ChMS.</p>
        <p>Apart from qualitative questions, you will also be asked for a Net Promoter Score (NPS), which helps confirm how recommendable this ChMS is.</p>
        <p>You will also be given options to share this helpful data with other church leaders who might be considering this ChMS.</p>
      </div>
      <div className="step-buttons">
        <Button className="cta-prev" onClick={() => prevPage()}>
          <img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;PREV</Button>
        <Button className="cta" onClick={() => nextPage()}>CONTINUE</Button>
      </div>
    </div>
  )
}

export default PreAdditionalInfo2;
