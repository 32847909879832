import { useState, useRef, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";

// import VideoThumbnail from '../Assets/Images/video-thumbnail.png';
import VideoThumbnail from '../Assets/Images/thumbnail.jpg';
import PlayButton from '../Assets/Images/play-button.png';
import Clock from '../Assets/Images/clock.png';
import '../App.scss';
import IntroImg from '../Assets/Images/intro-thumbnail.png';

const Start = props => {

  const [isLoading, setIsLoading]         = useState(true);

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = useState(false);
  const [startPlaying, setStartPlaying]   = useState(false);
  searchParams.get("leader");
  const vidRef = useRef(null);

  useEffect(() => {
		// document.body.style.backgroundColor = "#EEEEEE";
	},[]);

  const assessmentLink = () => {
    if([...searchParams]?.length) return "/assessment?" + searchParams;
    else return "/assessment";
  }

  const preAssessmentLink = () => {
    if([...searchParams]?.length) return "/before-you-start?" + searchParams;
    else return "/before-you-start";
  }

  const playVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.pause();
    setPlayingVideo(false);
  }

  // const showThumbnail = () => {
  //   if(playingVideo) {
  //     return <img alt="thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '0'}} onClick={(e) => pauseVideo(e)} />;
  //   } else {
  //     if(startPlaying) {
  //       return null
  //     } else {
  //       return <img alt="thumbnail" className="thumbnail" src={VideoThumbnail} />
  //     }
  //   }
  // }

  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      return <img alt="play button" className="playbtn" src={PlayButton} onClick={(e) => playVideo(e)} />
    }
  }

  const vidsrcURL = "";

  return (
    <div className="container start">
      <div className="video">
        <img className="thumbnail" src={IntroImg} alt="thumbnail" />
        <div className="owner-details">
          <h3 className="name">Kurian Babykutty</h3>
          <div class="position">CEO</div>
          <div class="company">40 Parables</div>
          <h3 class="name-mobile">Kurian Babykutty</h3>
          <span class="position-mobile">CEO, <span class="company-mobile">40 Parables</span></span>
        </div>

        {/* <video ref={vidRef}>
          <source
            src="https://40parables-assets.s3.amazonaws.com/cr_kurian.mp4"
            type="video/mp4"
            />
        </video>
        {showThumbnail()}
        {showPlayButton()} */}
      </div>
      <div className="intro">
        <h1>Are you and your staff aligned about your ChMS?</h1>
        {/* <h2>Unlock scores in 4 distinct areas of alignment in the leadership team of your Board Members + Head of School. Identify the key elements that may be blocking you from successfully funding your strategic vision.</h2> */}
        <ol className="intro-points-list">
          {searchParams.get("leader") ? <>
            <li>Take this 4-minute ChMS test</li>
            <li>View your results instantly</li>
            <li>Begin to align around action with your team</li>
          </> : <>
            <li>Take this 5-minute ChMS test</li>
            <li>Invite your team to take it too</li>
            <li>See all results on 1 dashboard</li>
            <li>Align your team around action</li>
          </>}
        </ol>
        <div className="completion">
          <Link to={preAssessmentLink()} className="cta">
            Get Started
          </Link>
          <div className='time-estimate'>
            <img className='clock-icon' src={Clock} alt="" /> <span>{searchParams.get("leader") ? "4" : "5"} min</span>
          </div>
        </div>
        {/* <div className="completion">Estimated time for completion: 3 min</div> */}
      </div>
    </div>
  );
}
export default Start;