import { createContext, useEffect, useState } from 'react';

const PreAdditionalQuestionContext = createContext({
  answers: [],
  updateAnswer: (updateAnswer) => {},
  senAns: (data) => {},
});

export function PreAdditionalQuestionContextProvider(props) {

  const [answers, setAnswers]     = useState(['','','','','','']);
  const [displayRatingRadio, setDisplayRatingRadio] = useState("");
  const [field1Values, setField1Values] = useState([
    { value: "ACS Technologies", label: "ACS Technologies" },
    { value: "Anedot", label: "Anedot" },
    { value: "Aplos Church", label: "Aplos Church" },
    { value: "Attendance Now", label: "Attendance Now" },
    { value: "Aware3", label: "Aware3" },
    { value: "Bitrix24", label: "Bitrix24" },
    { value: "Blackbaud Church Management", label: "Blackbaud Church Management" },
    { value: "Breeze", label: "Breeze" },
    { value: "CCIS Church Management Software", label: "CCIS Church Management Software" },
    { value: "CDM+", label: "CDM+" },
    { value: "CharityTracker", label: "CharityTracker" },
    { value: "Chaverware", label: "Chaverware" },
    { value: "ChMeetings", label: "ChMeetings" },
    { value: "Church Base", label: "Church Base" },
    { value: "Church Center", label: "Church Center" },
    { value: "Church Community Builder (CCB)", label: "Church Community Builder (CCB)" },
    { value: "Church Hero", label: "Church Hero" },
    { value: "Church MemberLink", label: "Church MemberLink" },
    { value: "Church Metrics", label: "Church Metrics" },
    { value: "Church Office Online", label: "Church Office Online" },
    { value: "Church Services", label: "Church Services" },
    { value: "Church Social", label: "Church Social" },
    { value: "Church Windows", label: "Church Windows" },
    { value: "ChurchDesk", label: "ChurchDesk" },
    { value: "ChurchSuite", label: "ChurchSuite" },
    { value: "ChurchTeams", label: "ChurchTeams" },
    { value: "ChurchThemes", label: "ChurchThemes" },
    { value: "ChurchTools", label: "ChurchTools" },
    { value: "ChurchTrac", label: "ChurchTrac" },
    { value: "Clearstream", label: "Clearstream" },
    { value: "Clover Sites", label: "Clover Sites" },
    { value: "Donarius", label: "Donarius" },
    { value: "Duplie", label: "Duplie" },
    { value: "eDisciples", label: "eDisciples" },
    { value: "Ekklesia 360", label: "Ekklesia 360" },
    { value: "Elexio Church Software", label: "Elexio Church Software" },
    { value: "Elvanto", label: "Elvanto" },
    { value: "eSPACE", label: "eSPACE" },
    { value: "Excellerate", label: "Excellerate" },
    { value: "EZTithe", label: "EZTithe" },
    { value: "Faith Teams", label: "Faith Teams" },
    { value: "Faithconnector", label: "Faithconnector" },
    { value: "Faithful Steward", label: "Faithful Steward" },
    { value: "FellowshipOne", label: "FellowshipOne" },
    { value: "FlockBase", label: "FlockBase" },
    { value: "Folknote", label: "Folknote" },
    { value: "Gabriel", label: "Gabriel" },
    { value: "IconCMO", label: "IconCMO" },
    { value: "Iglesia HOY", label: "Iglesia HOY" },
    { value: "infoodle", label: "infoodle" },
    { value: "KidCheck", label: "KidCheck" },
    { value: "Logos II", label: "Logos II" },
    { value: "Ministry Platform (ACS)", label: "Ministry Platform (ACS)" },
    { value: "Ministry Tracker", label: "Ministry Tracker" },
    { value: "Neon CRM", label: "Neon CRM" },
    { value: "Nucleus", label: "Nucleus" },
    { value: "One Church", label: "One Church" },
    { value: "Parish Data Systems (ACS)", label: "Parish Data Systems (ACS)" },
    { value: "ParishSOFT", label: "ParishSOFT" },
    { value: "Pastoral Care", label: "Pastoral Care" },
    { value: "PastorsLine", label: "PastorsLine" },
    { value: "PDS Church Office", label: "PDS Church Office" },
    { value: "Planning Center", label: "Planning Center" },
    { value: "PowerChurch Plus", label: "PowerChurch Plus" },
    { value: "Pushpay", label: "Pushpay" },
    { value: "Realm (ACS)", label: "Realm (ACS)" },
    { value: "Rock RMS", label: "Rock RMS" },
    { value: "Salesforce.org Nonprofit Cloud", label: "Salesforce.org Nonprofit Cloud" },
    { value: "Servant Keeper", label: "Servant Keeper" },
    { value: "Sharefaith", label: "Sharefaith" },
    { value: "Shelby Systems", label: "Shelby Systems" },
    { value: "SimpleChurch CRM", label: "SimpleChurch CRM" },
    { value: "SmartChurch", label: "SmartChurch" },
    { value: "Subsplash", label: "Subsplash" },
    { value: "Text In Church", label: "Text In Church" },
    { value: "Tithe.ly", label: "Tithe.ly" },
    { value: "ToucanTech", label: "ToucanTech" },
    { value: "TouchPoint", label: "TouchPoint" },
    { value: "UCare", label: "UCare" },
    { value: "Vanco Faith", label: "Vanco Faith" },
    { value: "Web Church Connect", label: "Web Church Connect" },
    { value: "Wild Apricot", label: "Wild Apricot" },
    { value: "eCatholic", label: "eCatholic" },
    { value: "Other", label: "Other" },
  ]);

  // const localStoredPreAddQuestions = JSON.parse(localStorage.getItem("preAdditionalAnswers"));
  // console.log("localStoredPreAddQuestions", localStoredPreAddQuestions);

  // useEffect(() => {
  //   if(localStoredPreAddQuestions?.length && answers.every(elm => elm == "")) {
  //     setAnswers(localStoredPreAddQuestions);
  //   }
  // }, [answers, localStoredPreAddQuestions]);

  useEffect(() => {
    const localStoredPreAddQuestions = JSON.parse(localStorage.getItem("preAdditionalAnswers"));
    console.log("localStoredPreAddQuestions", localStoredPreAddQuestions);
    if(localStoredPreAddQuestions?.length && localStoredPreAddQuestions.some(elm => elm != "")) {
      setAnswers(localStoredPreAddQuestions);
    }
  }, []);

  console.log("ctx answers", answers);

  function answersHandler(data) {
    setAnswers(data);
  }

  function updateAnswerHandler(Id, answer) {
    let newAnswers = answers;
    newAnswers[Id - 1] = answer;
    setAnswers(newAnswers); 
    let localAnswers  = [];
    newAnswers.forEach(q => localAnswers.push(q));
    localStorage.setItem('preAdditionalAnswers', JSON.stringify(localAnswers));
  }

  const context = {
    answers: answers,
    setAns: answersHandler,
    updateAnswer: updateAnswerHandler,
    field1Values: field1Values, 
    setField1Values: setField1Values,
    displayRatingRadio: displayRatingRadio, 
    setDisplayRatingRadio: setDisplayRatingRadio,
  };

  return <PreAdditionalQuestionContext.Provider value={context}>
    {props.children}
  </PreAdditionalQuestionContext.Provider>
}

export default PreAdditionalQuestionContext;
