import { useContext, useEffect } from "react";
import { Check } from '@mui/icons-material';
import { useLocation, useSearchParams, useParams } from "react-router-dom";
import AssessmentProgressContext from '../Store/AssessmentProgressContext';
import useWindowDimensions from "../Hooks/useWindowDimensions";

const AssessmentProgressSections = () => {

  const { windowWidth }   = useWindowDimensions();
  const assessProgressCtx = useContext(AssessmentProgressContext);
  const currentLocation   = useLocation();
  const currentPath       = currentLocation.pathname.split("/")[1];
  const pathsWithoutMainSections = ["", "start", "results", "dashboard", "invite-team", "invite-team-thanks"];

  const [searchParams, setSearchParams] = useSearchParams();

  let isMember = searchParams.get("leader") || searchParams.get("user");

  useEffect(() => {
    if(isMember) assessProgressCtx.setIsMember(true);
  }, [isMember])
  
  console.log("currentPath", `"${currentPath}"`);
  console.log("assessProgressCtx", assessProgressCtx);

  let widthPixels = 0;
  let sectionsWidth = 20 * assessProgressCtx.mainSections?.length;
  let pixels = assessProgressCtx.mainSections?.length === 3 ? 22 : 32;
  assessProgressCtx.mainSections?.forEach(sec => widthPixels += pixels);

  const statusClassNames = (status) => {
    switch (status) {
      case "unfinished": return "unfinished";
      case "active": return "active";
      case "finished": return "finished";
      default: return "unfinished";
    }
  }

  return pathsWithoutMainSections?.includes(currentPath) ? null : (
    <div className="assessment-progress-sections-container">

      <div className="main-sections-dots" style={{ width: `${sectionsWidth}%` }}>
        {assessProgressCtx.mainSections?.map((section, idx) => {
          return <div key={section.title} className="main-section">
            <div className={"main-section-dot " + statusClassNames(section?.status)}>{section?.status === "finished" ? <Check style={{ height: windowWidth > 650 ? "16px" : "9px", width: windowWidth > 650 ? "16px" : "9px", color: "white" }} sx={{ stroke: "#ffffff", strokeWidth: 3 }} /> : null}</div>
          </div>
        })}
      </div>
     
      <div className="main-sections-names" style={{ width: `calc(${sectionsWidth}% + ${widthPixels}px)` }}>
        {assessProgressCtx.mainSections?.map((section, idx) => {
          return <div key={section.title} className="main-section">
            <div className={"main-section-name " + statusClassNames(section?.status)}>{section?.title}</div>
          </div>
        })}
      </div>

    </div>
  )
}

export default AssessmentProgressSections;


