import { useState, useEffect, useContext } from "react";

import { Button } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useWindowDimensions from '../Hooks/useWindowDimensions';
import AssessmentProgressContext from "../Store/AssessmentProgressContext";

const PreAssessment = () => {

  const assessProgressCtx = useContext(AssessmentProgressContext);

  const { windowWidth }                   = useWindowDimensions();

  const [searchParams, setSearchParams]   = useSearchParams();
  let navigate                            = useNavigate();

  useEffect(() => {
		// document.body.style.backgroundColor = "#343333";
    assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => ({ ...sec, status: "unfinished" })));
	},[]);
  
  const assessmentLink = () => {
    if([...searchParams]?.length) return "/assessment?" + searchParams;
    else return "/assessment";
  }

  return (
    <div className="container pre-assessment">

      {/*<h1>Before You Start</h1>
      <p className="pre-assessment-subheader">For each of the following 21 church benchmarks, choose one of these three responses that accurately reflects your church's current situation.</p>
      <div className="explanation">
      <div className="marker red"></div><p>The benchmark is not in position to activate the Great Commission or multiply Kingdom impact. It is potentially broken or barely functioning to these standards, and demands attention.</p>
      </div>
      <div className="explanation">
      <div className="marker yellow"></div><p>The benchmark affects Great Commission activation and multiplies Kingdom impact but has room for improvement. It is not at its top level of effectiveness, and needs further action to become optimal.</p>
      </div>
      <div className="explanation">
      <div className="marker green"></div><p>The benchmark activates the Great Commission and multiplies Kingdom impact. It is effective and healthy, but might need slight improvement. Ensure it has the resources and attention it needs.</p>
      </div>*/}
      <h1>This test contains {searchParams.get("leader") ? "16" : "22"} questions divided into {searchParams.get("leader") ? "2" : "3"} sections each to help you comprehensively assess your ChMS:</h1>

      <div className="points-container">

        <p><span>Response:</span> 12 Likert scale questions that can help you and your team see what all of you think about your ChMS. <span style={{ fontWeight: 400 }}>(2 mins)</span></p>
        {searchParams.get("leader") ? null : <p><span>Relevance:</span> 6 contextual questions solely for you, to help you view your responses in the light of your unique church context. <span style={{ fontWeight: 400 }}>(1 min)</span></p>}
        <p><span>Rating:</span> 4 review questions to help you and your team rank and share your detailed opinion on your ChMS. <span style={{ fontWeight: 400 }}>(2 mins)</span></p>

      </div>

      <Link to={assessmentLink()} className="cta-link">
        <Button className="cta">TAKE THE TEST</Button>
      </Link>
      
    </div>
  )
}


export default PreAssessment;
