import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import AdditionalQuestionContext from '../Store/AdditionalQuestionContext';

// import QuestionTemp from '../Components/QuestionsTemp';
import Question from '../Components/Questions';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import nextArrowWhite from '../Assets/Images/next-arrow-white.svg';

import '../App.scss';
import AssessmentProgressContext from '../Store/AssessmentProgressContext';
import useWindowDimensions from "../Hooks/useWindowDimensions";


const AssessmentAdditional = () => {
  
  const { windowWidth }   = useWindowDimensions();
  let navigate            = useNavigate();
  const { id }            = useParams();
  const assessProgressCtx = useContext(AssessmentProgressContext);
  const addCtx 	          = useContext(AdditionalQuestionContext);
  console.log(addCtx);

  useEffect(() => {
    // document.body.style.backgroundColor = "#EEEEEE";
    assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => {
      if(sec.title === "Rating") return { ...sec, status: "active" };
      else return { ...sec, status: "finished" };
    }));

    getAdditional();
  }, []);
  

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [prevValue, setPrevValue] = useState(0);
  const [textInput, setTextInput] = useState("");
  
  // const [nextDisabled, setNextDisabled] = useState(true);

  let backFromDetailsPage = localStorage.getItem("backFromDetailsPage") === "true" ? true : false;
  console.log("backFromDetailsPage", backFromDetailsPage);
  useEffect(() => {
    if (backFromDetailsPage) setActiveStep(questions?.length - 1);
    else setActiveStep(0)
  }, [backFromDetailsPage, questions?.length])

  useEffect(() => {

  }, [backFromDetailsPage, activeStep])

  function getAdditional() {

    if(addCtx.questions.length > 0) {
      setQuestions(addCtx.questions);
      setQuestionCount(addCtx.questions.length);
      setIsLoading(false);
    } else {

      let clientId              = process.env.REACT_APP_CLIENT_ID;
    
      fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId + '/additional', {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if(data.status === "success") {
          setQuestions(data.questions);
          setQuestionCount(data.questions.length);
          addCtx.addQuestions(data.questions);
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      });

    }

    

  }

  function completeAssessmentAdditional() {

    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/additional', {
			method: 'PUT',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				questions
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status === "success") {
        setIsLoading(false);
        // if(data.member === true) {
        //   // navigate("/details/" + id + '?member=' + data.memberId + '&' + searchParams, { replace: true });
        //   navigate("/post-additional-info/" + id + '?' + searchParams, { replace: true });
        // } else 
        navigate("/post-additional-info/" + id + '?' + searchParams, { replace: true });
			} else {
        setIsLoading(false);
				// setOpenSnackbar(true);
			}
		});
	}

  const prevQuestion = () => {
    if(activeStep === 0) {
      // if(searchParams.get("leader") || searchParams.get("member")) {
      //   navigate("/pre-additional-info2/" + id + '?' + searchParams, { replace: true });
      // } else 
      navigate("/pre-additional-info2/" + id + '?' + searchParams, { replace: true });
    } else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1].answer);
    }
  };

  // const nextQuestion = () => {
  //   if(activeStep < questions.length) {
  //     console.log("test", questions[activeStep + 1].id, questions[activeStep + 1].answer);
  //     console.log("test", questions[activeStep + 1].id, questions[activeStep + 1].answerTxt);
  //     // setTimeout(() => {
  //       setActiveStep((prev) => prev + 1);
  //       setPrevValue(questions[activeStep + 1].answer);
  //       addCtx.updateAnswer(questions[activeStep + 1].id, questions[activeStep + 1].answer);
  //       setTextInput("");
  //     // }, 50);
  //   } else {
  //     setTimeout(() => { completeAssessmentAdditional(); }, 500);
  //   }
  // };
  const nextQuestion = () => {
    // console.log("activeStep", activeStep); 
    // console.log("questions.length - 1", questions.length - 1);
    if(activeStep < questions.length - 1) {
      addCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
      addCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
      setTimeout(() => {
        setTextInput("");
        setActiveStep(activeStep + 1);
        setPrevValue(questions[activeStep + 1].answer);
      }, 20);
    } else {
      setTimeout(() => { completeAssessmentAdditional(); }, 500);
    }
  };

  console.log("questions", questions);
  console.log("addCtx.questions", addCtx.questions);
  console.log("questions[activeStep]", questions[activeStep]);

  const moveToNextQuestion = (answer) => {
    questions[activeStep].answer = answer;
    addCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
    addCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
    if(activeStep + 1 < questions.length) {
      setTimeout(() => {
        setActiveStep(activeStep + 1);
      }, 500);
    } else {      
      setTimeout(() => { completeAssessmentAdditional(); }, 500);
    }
  }

  const updateTextQuestion = (answer) => {
    setTextInput(answer);
    // addCtx.updateTextAnswer(questions[activeStep + 1].id, answer);
  }

  const nextDisabled = (answer) => {
    if(questions[activeStep]?.mandatory === "no") {
      return {display: "block"};
    } else {
      if(questions[activeStep]?.type === "slider" && questions[activeStep]?.answer !== 0) {
        return {display: "block"};
      } else if(questions[activeStep]?.type === "textarea" && questions[activeStep]?.answerTxt !== "") {
        return {display: "block"};
      }
    } 
    return {display: "none"};
  }

  // const updateQuestion = (event, step) => {
  //   setTextField(event.target.value);
  //   questions[step].answer = event.target.value;
  //   // return true;
  // }
  
  const nextQuestionClass = () => {
    if(activeStep === 0) {
      return "";
    } else if(activeStep === questions?.length - 1) {
      return "all-set-btn nextbtn";
    } else return "nextbtn";
  }

  const nextArrowFunc = () => {
    if(activeStep === 0) {
      return nextArrow;
    }
    return nextArrowWhite;
  }

  const getStepContent = (step) => {
    return (
      <Question question={questions[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} textAnswer={updateTextQuestion} currentValue={prevValue}/>
    );
  };

  // const getStepContent = (step) => {
  //   if(step === 0) {
  //     return (
  //       <QuestionTemp question={questions[step].question} questionAnswered={moveToNextQuestion} currentValue={prevValue}/>
  //     );
  //   } else {
  //     return <div>
  //       <div className="question textarea">
  //         <p dangerouslySetInnerHTML={{__html: questions[step].question}}></p>
  //       </div>
  //       <div className="textarea-field">
  //         <TextField hiddenLabel multiline rows={4} placeholder="Please type your response here" value={textField}  onChange={(e) => {updateQuestion(e, step)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
  //       </div>
  //     </div>
  //   }
    
  // };

  const showNextButton = () => {
    if(activeStep === 2) {
      return <>
        You're all set!
      </>;
    } else if(activeStep === 1) {
      return <>
        Next&nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" />
      </>;
    } else {
      return <>
        &nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" />
      </>;
    }
  }


  if (isLoading) {
		return <div className="container assessment-additional loading"><CircularProgress style={{ color: '#F7D54D' }} size={60} /></div>
	}

  return (
    <div className="container assessment-additional">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">
              <div className="spacer" style={{ height: windowWidth > 650 ? "50px" : "20px" }}></div>

              <h1>RATING YOUR ChMS</h1>

              {/* <div className="spacer"></div> */}
              <div className="progress-indicator"><strong>{activeStep + 1}/{questions.length}</strong></div>

              {getStepContent(activeStep)}

              {/* <div className="step-buttons">
                <Button onClick={prevQuestion}>
                  <img src={prevArrow} />&nbsp;&nbsp;
                </Button>
                <Button className={nextQuestionClass()} onClick={nextQuestion} style={nextDisabled()}>
                  {showNextButton()}
                </Button>
              </div> */}
              
            </div>
          </div>
        </div>
      </div>
      
      <div className="step-buttons">
        <Button className="cta-prev" onClick={() => prevQuestion()}>
          <img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;PREV</Button>
        <Button className={"cta " + nextQuestionClass()} style={nextDisabled()} onClick={() => nextQuestion()}>NEXT&nbsp;&nbsp;<img src={nextArrow} alt="Previous Page Arrow" /></Button>
      </div>
      
    </div>
  );
}
export default AssessmentAdditional;