import { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import prevArrow from "../Assets/Images/prev-arrow.svg";
import nextArrow from "../Assets/Images/next-arrow.svg";
import AssessmentProgressContext from "../Store/AssessmentProgressContext";
import { pink } from "@mui/material/colors";
import PreAdditionalQuestionContext from "../Store/PreAdditionalQuestionContext";

const PostAdditionalInfo = () => {

  const { id }                          = useParams();
  const assessProgressCtx               = useContext(AssessmentProgressContext);
  const preAddCtx                       = useContext(PreAdditionalQuestionContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate                        = useNavigate();

  function getNext() {
    {/*fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/review', {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
				setSections(data.sections);
				setQuestions(data.questions);
        setQuestionCount(data.questions.length);
				setHasAdditional(data.hasAdditional);
        setIsLoading(false);
        setActiveStep(data.questions.length - 1);
        setPrevValue(data.questions[data.questions.length - 1].answer);
        setCurrentHash(id);
			} else {
				// setOpenSnackbar(true);
			}
		});*/}
  }

  useEffect(() => {
    // so that last additional question is shown when going back from this page 
    localStorage.setItem("backFromDetailsPage", "true");
    assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => {
      return { ...sec, status: "finished" };
    }));
  }, []);

  const nextPage = () => {
    navigate("/details/" + id + '?' + searchParams, { replace: true });
    // getNext();
    // if(searchParams.get("leader")) {
    //   navigate("/assessment-additional/" + id + "?" + searchParams, { replace: true });
    // } else navigate("/assessment-additional/" + id, { replace: true });
  }

  
  const prevPage = () => {
    navigate("/assessment-additional/" + id + '?' + searchParams, { replace: true });
    // if(!searchParams.get("leader")) {
    // } else navigate("/assessment/" + id + "/review?" + searchParams, { replace: true });
  }

    
    console.log("choice", preAddCtx.displayRatingRadio, );

  return (
    <div className="container pre-additional-info post-additional-info">
      <h1>You're done with all the questions. Great job!</h1>
      <div className="text-container">
        <p>At Bleat, our goal is to help you hear the voice of your sheep so that you can make better decisions and bolder disciples. Thousands of church leaders like you have already taken similar tests to better evaluate and serve their churches.</p>
        <p>We're building a review platform to help churches like yours know how peer pastors are making key decisions. Sharing just your last 4 responses (Like/Dislike/Preference/NPS) on our review site will help other church leaders evaluate this ChMS with better context.</p>
        <p>How would you like us to display your ratings for these last 4 questions on our platform?</p>
      </div>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        className="radio"
        value={preAddCtx.displayRatingRadio}
        onChange={(e) => preAddCtx.setDisplayRatingRadio(e.target.value)}
      >
        <FormControlLabel value="display" control={<Radio />} label="Display my rating" />
        <FormControlLabel value="anon_display" control={<Radio />} label="Display my rating anonymously" />
        <FormControlLabel value="no_display" control={<Radio />} label="Don't display my rating" />
      </RadioGroup>
      {/* <p style={{ display: "block", color: "crimson", fontSize: "16px", textAlign: "left", margin: "15px auto", padding: "0 30px", maxWidth: "45ch" }} className="error-text">Please confirm how you'd like your rating to be displayed.</p> */}
      <div className="step-buttons">
        <Button className="cta-prev" onClick={() => prevPage()}>
          <img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;PREV</Button>
        {preAddCtx.displayRatingRadio && <Button className="cta" onClick={() => nextPage()}>CONTINUE</Button>}
      </div>
    </div>
  )
}

export default PostAdditionalInfo;
